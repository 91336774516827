<template>
  <div class="information">
    <titleNav title="社保小助手" :left-arrow="true" left-text="" />
    <div class="insured">参保信息</div>
    <div class="box_lidy">
      <div class="one"></div>
      <div class="two"></div>
    </div>
    <div class="city">石家庄/灵活就业养老医疗办理</div>
    <van-cell is-link @click="showPopup" class="cell"
      >缴纳开始的月份 <van-icon class="icon" name="arrow" />
      <div class="ciols">{{ yf }}</div></van-cell
    >
    <van-popup v-model="show" position="bottom"
      ><van-picker
        show-toolbar
        :columns="columns"
        @confirm="onConfirm"
        @cancel="onCancel"
    /></van-popup>
    <div class="ps">请拍摄实体身份证<span @click="shot">拍摄要求</span></div>
    <div class="upload-img">
      <van-uploader
        :after-read="onReads"
        accept="image/*"
        style="width: 100%; height: 100%"
      >
        <img
          v-if="imga == ''"
          src="@/assets/images/card.png"
          style="
            width: 92vw;
            height: 230px;
            border: 2px dashed #000000;
            border-radius: 3px;
          "
        />
        <img
          v-else
          :src="imga"
          style="
            width: 92vw;
            height: 230px;
            border: 2px dashed #000000;
            border-radius: 3px;
          "
        />
      </van-uploader>
    </div>
    <div class="upload-img">
      <van-uploader
        :after-read="onRead"
        accept="image/*"
        style="width: 100%; height: 100%"
      >
        <img
          v-if="imgb == ''"
          src="@/assets/images/carend.png"
          style="
            width: 92vw;
            height: 230px;
            border: 2px dashed #000000;
            border-radius: 3px;
          "
        />
        <img
          v-else
          :src="imgb"
          style="
            width: 92vw;
            height: 230px;
            border: 2px dashed #000000;
            border-radius: 3px;
          "
        />
      </van-uploader>
    </div>

    <!-- <div class="images">
      <img
        class="img"
        src="@/assets/images/carend.png"
        alt=""
        v-if="qianImgeh == ''"
      />
    </div> -->
    <!-- <div class="sf">根据你的身份证照片识别出以下信息,请确认信息并提交</div> -->
    <div>
      <van-cell-group style="color: #000000">
        <van-field
          v-model="name"
          label-width="90"
          label="姓名"
          placeholder="请输入姓名"
          style="font-size: 18px; color: #000000"
        />
        <van-field
          v-model="id"
          label-width="90"
          maxlength="18"
          label="身份证号"
          placeholder="请输入身份证号"
          style="font-size: 18px; color: #000000"
        />
        <van-field
          v-model="phone"
          label-width="90"
          type="number"
          maxlength="11"
          label="手机号"
          placeholder="请输入手机号"
          style="font-size: 18px; color: #000000"
        />
        <van-field
          v-model="emile"
          label-width="90"
          label="邮箱号"
          placeholder="请输入邮箱号(选填)"
          style="font-size: 18px; color: #000000"
        />
      </van-cell-group>
    </div>
    <div style="margin-top: 10px; color: #646566">
      <div class="choice">请选择</div>
      <van-radio-group
        v-model="radio"
        direction="horizontal"
        style="font-size: 18px; margin-left: 30; pxmargin-top: 10px"
      >
        <van-radio name="1">养老</van-radio>
        <van-radio name="2">养老医疗</van-radio>
      </van-radio-group>
    </div>
    <div class="yh" style="">
      <van-radio-group v-model="radios" style="margin-left: 20px">
        <van-radio name="1"
          >我已阅读并同意
          <p @click="aadian()" style="color: #1296db; display: inline-block">
            《用户服务协议》
          </p>
          和
          <p @click="badian()" style="color: #1296db; display: inline-block">
            《隐私政策》
          </p></van-radio
        >
      </van-radio-group>
    </div>
    <div class="but">
      <button @click="handle">下一步</button>
    </div>

    <!-- 悬浮客服 -->
    <div class="content">
      <div
        :style="{ transform: 'translate3d(' + xMove + 'px,' + yMove + 'px,0)' }"
        class="touch"
        @touchstart="handleStart"
        @touchmove="handleMove"
        @touchend="handleEnd"
      >
        <div class="div" @click="service">
          <img src="../../assets/images/lanxi.png" alt="" />
          联系客服
        </div>
        <!-- <button class="buttons" open-type="contact" session-from="">
          
          联系客服
        </button> -->
      </div>
    </div>
  </div>
</template>

<script>
var curPoint = {
  x: 0,
  y: 0,
}; // 记录原点
var startPoint = {};
// 标志位（只触发点击事件按，并没有移动-就不必触发end事件）
var isTouchMove = false;
import titleNav from "@/components/conetnts/titleNav.vue";
import {
  Popup,
  Picker,
  Toast,
  Icon,
  Field,
  RadioGroup,
  Radio,
  Uploader,
  Dialog,
} from "vant";
import axios from "axios";
import { getimg, order, info, price } from "@/network/index.js";
import canvasDataURL from "../../assets/js/img.js";
export default {
  components: {
    titleNav,
    [Popup.name]: Popup,
    [Picker.name]: Picker,
    [Toast.name]: Toast,
    [Icon.name]: Icon,
    [Field.name]: Field,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
    [Uploader.name]: Uploader,
    [Dialog.name]: Dialog,
  },
  data() {
    return {
      xMove: 0,
      yMove: 0,
      name: "",
      id: "",
      // 130124199005080940
      names: "",
      ids: "",
      phone: "",
      emile: "",
      radio: "2",
      radios: "",
      datas: {},
      show: false,
      imga: "",
      imgb: "",
      imgaUrl: "",
      imgbUrl: "",
      fileListLeft: [],
      uploadImages: [],
      Light_upload: true, //是否展示上传区域
      columns: [
        "1月",
        "2月",
        "3月",
        "4月",
        "5月",
        "6月",
        "7月",
        "8月",
        "9月",
        "10月",
        "11月",
        "12月",
      ],
      yuefen: "01",
      qianImgehand: "", //身份证人像面
      qianImgeh: "", //身份证国徽面
      yf: "1月",
      valid_date: "", //身份证有效期
    };
  },
  mounted() {
    this.infoget();
    this.add();
  },
  methods: {
    infoget() {
      let userid = sessionStorage.getItem("id");
      let data = {
        userid,
      };
      info(data).then((res) => {
        if (
          res.data.data.phone != undefined ||
          res.data.data.phone != "" ||
          res.data.data.phone != null
        ) {
          this.phone = res.data.data.phone;
        } else {
          this.phone = "";
        }
      });
    },
    // 点击客服
    service() {
      // let url = "https://work.weixin.qq.com/kfid/kfc2de3dc57f9907732";
      let url = "http://www.sjzydrj.net/index.php/Home/News/index/id/93.html";

      // window.localtion.href = url
      window.location.replace(
        // "https://work.weixin.qq.com/kfid/kfc2de3dc57f9907732"
        "http://www.sjzydrj.net/index.php/Home/News/index/id/93.html"
      );
    },
    // 悬浮客服
    handleStart(ev) {
      // console.log('start',ev);
      // 记录一开始手指按下的坐标
      var touch = ev.changedTouches[0];
      startPoint.x = touch.pageX;
      startPoint.y = touch.pageY;
    },
    handleMove(ev) {
      // console.log('move',ev);
      // 防止页面高度很大，出现滚动条，不能移动-默认拖动滚动条事件
      ev.preventDefault();

      isTouchMove = true;

      var touch = ev.changedTouches[0];
      var diffPonit = {}; // 存放差值
      var movePonit = {
        // 记录移动的距离
        x: 0,
        y: 0,
      };
      diffPonit.x = touch.pageX - startPoint.x;
      diffPonit.y = touch.pageY - startPoint.y;
      // 移动的距离 = 差值 + 当前坐标点
      movePonit.x = diffPonit.x + curPoint.x;
      movePonit.y = diffPonit.y + curPoint.y;
      this.move(movePonit.x, movePonit.y);
    },
    handleEnd(ev) {
      // console.log('end', ev);
      if (!isTouchMove) return;

      //  更新坐标原点
      var touch = ev.changedTouches[0];

      curPoint.x += touch.pageX - startPoint.x;
      curPoint.y += touch.pageY - startPoint.y;

      // 重置
      isTouchMove = false;
    },
    move(x, y) {
      x = x || 0; // 没有传就是0
      y = y || 0;
      this.xMove = x;
      this.yMove = y;
      // translate3d  (tx,ty,tz)  在X轴偏移tx，在Y轴偏移ty，在Z轴偏移tz，单位px
    },
    add() {
      let add = JSON.parse(sessionStorage.getItem("add"));
      if (add !== null) {
        (this.imga = add.imga),
          (this.imgb = add.imgb),
          (this.name = add.name),
          (this.id = add.id),
          (this.phone = add.phone),
          (this.emile = add.emile),
          (this.radio = add.radio),
          (this.ids = add.ids),
          (this.valid_date = add.valid_date),
          (this.names = add.names),
          (this.yuefen = add.yuefen); //缴费月份
      }
    },
    // base64转为file
    dataURLtoFile(dataurl, filename) {
      let arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, {
        type: mime,
      });
    },
    // 人像面
    /* onReads(file) {
      var _this = this;
      let fileReader = new FileReader();
      fileReader.readAsDataURL(file.file);
      fileReader.onload = function (res) {
        let baser64 = this.result;
        let obj = {
          width: 500,
          height: 550,
          getFlag: "6",
        };
        canvasDataURL(baser64, obj, (value) => {
          let file = _this.dataURLtoFile(value, "idcard_front" + ".jpg");
          var formData = new FormData();
          formData.append("file", file);
          getimg(formData).then((res) => {
            _this.imga = res.data.url;
          });
        });
      };
    }, */
    onReads(e) {
      const file = e.file;
      var _this = this;
      let img = document.createElement("img");
      let cvs = document.createElement("canvas");
      //  上传的图片大于 500KB 时才压缩
      if (file && file.size / 1024 > 500) {
        let reader = new FileReader();
        reader.readAsDataURL(file); //  转成 base64 编码
        reader.onload = function (e) {
          let naturalBase64 = e.target.result; //  获取 base64 编码，这是原图的
          img.src = naturalBase64;
          img.onload = async function () {
            let ratio = img.naturalWidth / img.naturalHeight; //  获取原图比例,为了等比压缩
            cvs.width = 400;
            cvs.height = cvs.width / ratio;
            let ctx = cvs.getContext("2d");
            ctx.drawImage(img, 0, 0, cvs.width, cvs.height); //  画在 canvas 上
            // 压缩后新图的 base64
            let zipBase64 = cvs.toDataURL();
            const result = await getimg({
              base64: zipBase64.replace(/^data:image\/\w+;base64,/, ""),
            });
            if (result.data.code == 200) {
              _this.imga = zipBase64;
              _this.imgaUrl = result.data.url;
            } else {
              Toast.fail("证件上传失败");
            }
          };
        };
      } else {
        let reader = new FileReader();
        reader.readAsDataURL(file); //  转成 base64 编码
        reader.onload = async function (e) {
          let naturalBase64 = e.target.result;
          const result = await getimg({
            base64: naturalBase64.replace(/^data:image\/\w+;base64,/, ""),
          });
          if (result.data.code == 200) {
            _this.imga = naturalBase64;
            _this.imgaUrl = result.data.url;
          } else {
            Toast.fail("证件上传失败");
          }
        };
      }
    },
    // 国徽面
    /* onRead(file) {
      var _this = this;
      let fileReader = new FileReader();
      fileReader.readAsDataURL(file.file);
      fileReader.onload = function (res) {
        let baser64 = this.result;
        let obj = {
          width: 500,
          height: 550,
          getFlag: "6",
        };
        canvasDataURL(baser64, obj, (value) => {
          let file = _this.dataURLtoFile(value, "idcard_back" + ".jpg");
          var formData = new FormData();
          formData.append("file", file);
          getimg(formData).then((res) => {
            console.log(res);
            _this.imgb = res.data.url;
          });
        });
      };
    }, */
    // 国徽面
    onRead(e) {
      const file = e.file;
      var _this = this;
      let img = document.createElement("img");
      let cvs = document.createElement("canvas");
      //  上传的图片大于 500KB 时才压缩
      if (file && file.size / 1024 > 500) {
        let reader = new FileReader();
        reader.readAsDataURL(file); //  转成 base64 编码
        reader.onload = function (e) {
          let naturalBase64 = e.target.result; //  获取 base64 编码，这是原图的
          img.src = naturalBase64;
          img.onload = async function () {
            let ratio = img.naturalWidth / img.naturalHeight; //  获取原图比例,为了等比压缩
            cvs.width = 400;
            cvs.height = cvs.width / ratio;
            let ctx = cvs.getContext("2d");
            ctx.drawImage(img, 0, 0, cvs.width, cvs.height); //  画在 canvas 上
            // 压缩后新图的 base64
            let zipBase64 = cvs.toDataURL();
            const result = await getimg({
              base64: zipBase64.replace(/^data:image\/\w+;base64,/, ""),
            });
            if (result.data.code == 200) {
              _this.imgb = zipBase64;
              _this.imgbUrl = result.data.url;
            } else {
              Toast.fail("证件上传失败");
            }
          };
        };
      } else {
        let reader = new FileReader();
        reader.readAsDataURL(file); //  转成 base64 编码
        reader.onload = async function (e) {
          let naturalBase64 = e.target.result;
          const result = await getimg({
            base64: naturalBase64.replace(/^data:image\/\w+;base64,/, ""),
          });
          if (result.data.code == 200) {
            _this.imgb = naturalBase64;
            _this.imgbUrl = result.data.url;
          } else {
            Toast.fail("证件上传失败");
          }
        };
      }
    },
    onConfirm(value, index) {
      this.yf = value;
      if (value == "1月") {
        this.yuefen = "01";
      } else if (value == "2月") {
        this.yuefen = "02";
      } else if (value == "3月") {
        this.yuefen = "03";
      } else if (value == "4月") {
        this.yuefen = "04";
      } else if (value == "5月") {
        this.yuefen = "05";
      } else if (value == "6月") {
        this.yuefen = "06";
      } else if (value == "7月") {
        this.yuefen = "07";
      } else if (value == "8月") {
        this.yuefen = "08";
      } else if (value == "9月") {
        this.yuefen = "09";
      } else if (value == "10月") {
        this.yuefen = "10";
      } else if (value == "11月") {
        this.yuefen = "11";
      } else {
        this.yuefen = "12";
      }
      this.show = false;
    },
    onCancel() {
      this.show = false;
    },
    showPopup() {
      this.show = true;
    },
    handle() {
      var _this = this;
      if (this.radios == "") {
        Toast("请同意《用户服务协议》和《隐私政策》");
      } else {
        // var nameReg = /^[\u4E00-\u9FA5]{2,5}$/; //姓名正则
        // if (!nameReg.test(_this.name)) {
        if (_this.name == "") {
          Toast.fail("请输入正确格式的姓名");
          return;
        }
        var regIdNo = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/; //身份证号正则
        if (!regIdNo.test(_this.id)) {
          Toast.fail("请输入正确格式的身份证");
          return;
        }
        if (_this.phone.length != 11) {
          Toast.fail("请输入正确格式的手机号");
          return;
        }
        if (_this.imga == "") {
          Toast.fail("请上传身份证人像面");
          return;
        }
        if (_this.imgb == "") {
          Toast.fail("请上传身份证国徽面");
          return;
        }
        let id = sessionStorage.getItem("id");

        let data = {
          idcard_front: _this.imgaUrl,
          idcard_back: _this.imgbUrl,
          true_name: this.$aes.encrypt(_this.name),
          true_num: this.$aes.encrypt(_this.id),
          phone: this.$aes.encrypt(_this.phone),
          email: _this.emile,
          order_type: _this.radio,
          idcard_num: _this.ids,
          idcard_validity: _this.valid_date,
          idcard_name: _this.names,
          is_sjz: 1,
          id: _this.id,
          userid: id, //登录获取的id号
          msg_result: "reject", //订阅消息
          start_time: _this.yuefen, //缴费月份
        };
        order(data).then((res) => {
          console.log(res);
          if (res.data.code == 200) {
            let data = res.data.msg;
            // let data = "00ff238ff04a7e11d34a7c34cf866c20";
            let add = {
              imga: _this.imga,
              imgb: _this.imgb,
              name: _this.name,
              id: _this.id,
              phone: _this.phone,
              emile: _this.emile,
              radio: _this.radio,
              ids: _this.ids,
              valid_date: _this.valid_date,
              names: _this.names,
              yuefen: _this.yuefen, //缴费月份
            };
            sessionStorage.setItem("add", JSON.stringify(add));

            this.$router.push(
              "/qianming?data=" + data + "&identification=" + 2
            );
          } else {
            Toast.fail(res.data.msg);
          }
        });
        // on cancel
      }
    },
    // 拍摄要求
    shot() {
      let add = {
        imga: this.imga,
        imgb: this.imgb,
        name: this.name,
        id: this.id,
        phone: this.phone,
        emile: this.emile,
        radio: this.radio,
        ids: this.ids,
        valid_date: this.valid_date,
        names: this.names,
        yuefen: this.yuefen, //缴费月份
      };
      sessionStorage.setItem("add", JSON.stringify(add));
      this.$router.push("/shot");
    },
    // 用户服务和隐私协议
    aadian() {
      let add = {
        imga: this.imga,
        imgb: this.imgb,
        name: this.name,
        id: this.id,
        phone: this.phone,
        emile: this.emile,
        radio: this.radio,
        ids: this.ids,
        valid_date: this.valid_date,
        names: this.names,
        yuefen: this.yuefen, //缴费月份
      };
      sessionStorage.setItem("add", JSON.stringify(add));
      this.$router.push("/texta");
    },
    badian() {
      let add = {
        imga: this.imga,
        imgb: this.imgb,
        name: this.name,
        id: this.id,
        phone: this.phone,
        emile: this.emile,
        radio: this.radio,
        ids: this.ids,
        valid_date: this.valid_date,
        names: this.names,
        yuefen: this.yuefen, //缴费月份
      };
      sessionStorage.setItem("add", JSON.stringify(add));

      this.$router.push("/textb");
    },
  },
};
</script>

<style scoped lang="scss">
// 悬浮客服
.content {
  z-index: 999;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  // height: 100vh;
}
.touch {
  position: fixed;
  right: 20px;
  bottom: 45vh;
  width: 60px;
  height: 60px;
  /* 知识点
	        line-height是行高，针对的对象是文字，height针对的是容器，
	        也就是高度，当height和line-height值相同时会居中，
	        当line-height值小于height时文字向上移动，反之向下移动。
	         */
  // line-height: 45px; /* 文字垂直居中 */
  text-align: center; /* 水平居中 */
  border-radius: 50%;
  color: #fff;
  /* 去除标签点击事件高亮效果 */
  -webkit-tap-highlight-color: transparent;
  /* 使用transform: translate3d 处理性能高 GUP */
  .div {
    width: 100%;
    height: 100%;
    color: #000000;
    font-size: 14px;
    background-color: #ffffff;
    border-radius: 5px;
    border: 1px solid #c5c5c5;
    img {
      width: 25px;
      margin-left: 17.5px;
      padding-top: 8px;
      display: block;
    }
  }
}

.information {
  overflow-y: hidden;
  .insured {
    font-size: 2.5vh;
    font-weight: 700;
    margin-top: 15px;
    margin-left: 15px;
  }
  .box_lidy {
    display: flex;
    padding: 15px 30px 15px 15px;
    justify-content: space-between;
    width: 80px;
    .one {
      width: 35px;
      background: #1d80de;
      height: 3px;
    }

    .two {
      /* margin: 0 20rpxx; */
      width: 35px;
      background: #dddddd;
      height: 3px;
    }
  }
  .city {
    padding: 0 15px;
    color: #7e7e7e;
    font-size: 2vh;
  }
  .cell {
    font-size: 2vh;
    padding: 0 15px;
    width: 100%;
    margin-top: 0;
  }
  .icon {
    float: right;
    margin-top: 18px;
    margin-right: 15px;
  }
  .ciols {
    float: right;
    margin-top: 15px;
    color: #858585;
  }
  .ps {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 2vh;
    text-align: center;
    color: #858585;
    span {
      color: #4f83ab;
    }
  }
  .upload-img {
    margin: 5px 0px;
    width: 92vw;
    margin-left: 4vw;
  }
  .images {
    margin: 5px 0px;
    width: 92vw;
    margin-left: 4vw;
    .img {
      background: url("../../assets/images/card.png") no-repeat;
      background-size: 100% 100%;
      width: 100%;
      height: 60vw;
      border: 2px dashed #000000;
      border-radius: 3px;
    }
  }
  .sf {
    font-size: 1.8vh;
    width: 92vw;
    margin-left: 4vw;
    color: #858585;
  }
  .choice {
    font-size: 18px;
    float: left;
    margin-left: 15px;
    width: 100px;
  }
  .yh {
    font-size: 18px;
    width: 92vw;
    margin: 20px 4vw;
  }
  .but {
    // position: fixed;
    // bottom: 0;
    width: 100%;
    height: 50px;
    background-color: #ffffff;
    font-size: 20px;
    button {
      background-color: #0f81fd;
      color: #ffffff;
      border: 0;
      border-radius: 5px;
      width: 90vw;
      margin-left: 5vw;
      text-align: center;
      height: 40px;
    }
  }
}
</style>
